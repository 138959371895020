import PropTypes from "prop-types";
import React, { useContext } from "react";
import { withTheme } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Section from "../UI/Section";
import Container from "../UI/Container";
import CustomImage from "../UI/CustomImage";
import Link from "../UI/Link";
import Icon from "../UI/Icon";
import Markdown from "../UI/Markdown";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { LangContext } from "./langContext";
import PatternLeft from "../../theme/img/pattern-left-primary-dark.svg";
import PatternRight from "../../theme/img/pattern-right-primary-dark.svg";

const FooterRaw = ({ theme, menusSettings, footerSettings, productsList, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  var telLink = "tel:";
  var mailTo = "mailto:";
  const { currentLanguage } = useContext(LangContext);
  const mainMenu = currentLanguage == "fr" ? menusSettings.mainmenu : menusSettings.mainmenuen;

  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Section
      bg="footer"
      color="#fff"
      py={[0, 0, 0, 0]}
      sx={{
        position: "relative",
        "&:before, &:after": {
          content: "''",
          display: ["none", "none", "none", "none", "block"],
          width: "90px",
          height: "100%",
          position: "absolute",
          top: 0,
          backgroundRepeat: "repeat-y",
          backgroundSize: "100% auto",
          zIndex: 1
        },
        "&:before": {
          left: 0,
          backgroundImage: "url(" + PatternLeft + ")"
        },
        "&:after": {
          right: 0,
          backgroundImage: "url(" + PatternRight + ")"
        },
        "& > *": {
          position: "relative",
          zIndex: 2
        }
      }}
    >
      <Container py={[6, 7, 8]}>
        <Flex justifyContent="center" px={[0, 6, 0]}>
          <Flex
            justifyContent={["center", "space-between"]}
            flexDirection={["column", "row"]}
            flexWrap="wrap"
            width={["auto", "100%"]}
            sx={{
              a: {
                color: "white",
                opacity: ".6",
                textDecoration: "none",
                "&:hover": {
                  color: "white",
                  textDecoration: "underline",
                  opacity: "1"
                }
              }
            }}
          >
            <Box mr={[0, 0, 6, 8]} py={6} width={["auto", "100%", "auto"]}>
              <CustomImage
                img={footerSettings.logoFooter}
                height="auto"
                width="170px"
                mx={[0, "auto", 0]}
              />
              <Box mt={4} textAlign={["left", "center", "left"]}>
                <Text fontWeight="bold" mb={3}>
                  {footerSettings.companyName}
                </Text>
                <Markdown>{footerSettings.addressFooter}</Markdown>
              </Box>
            </Box>
            <Box py={6}>
              <Text fontWeight="bold" mb={[4, 4, 6]}>
                Menu
              </Text>
              {mainMenu.map((item, i) => (
                <Box mt={3} key={"menu-" + i} sx={{ "a.active": { opacity: "1" } }}>
                  <Link activeClassName="active" href={item.path} external={item.external}>
                    {item.title}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box py={6}>
              <Text fontWeight="bold" mb={[4, 4, 6]}>
                {currentLanguage == "en" ? "Our products" : "Nos produits"}
              </Text>
              <Box>
                {productsList.edges.map(
                  (item, i) =>
                    item.node.fields.slug.endsWith("." + currentLanguage + "/") && (
                      <Box mt={3} key={"menu-prod-" + i} sx={{ "a.active": { opacity: "1" } }}>
                        <Link
                          href={
                            currentLanguage == "fr"
                              ? "/fr" + item.node.frontmatter.slug
                              : item.node.frontmatter.slug
                          }
                        >
                          {item.node.frontmatter.title}
                        </Link>
                      </Box>
                    )
                )}
              </Box>
            </Box>
            {footerSettings.socials && (
              <Box py={6}>
                <Text fontWeight="bold" mb={[4, 4, 6]}>
                  {currentLanguage == "en" ? "Follow us" : "Suivez-nous"}
                </Text>
                <Flex>
                  {footerSettings.socials.facebook && (
                    <Box
                      mr={4}
                      sx={{
                        a: {
                          textDecoration: "none",
                          div: {
                            opacity: ".6"
                          },
                          "&:hover": {
                            div: {
                              opacity: "1"
                            }
                          }
                        }
                      }}
                    >
                      <a href={footerSettings.socials.facebook}>
                        <Box color="white">
                          <Icon icon="facebook" size={40} />
                        </Box>
                      </a>
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        </Flex>
      </Container>

      <Box bg="primaryDark" fontSize={2}>
        <Container>
          <Flex
            justifyContent={["center"]}
            flexDirection={["column", "column", "column", "column", "row"]}
            alignItems={["center"]}
            flexWrap="wrap"
            py={5}
            sx={{
              a: {
                color: "white",
                textDecoration: "none",
                "& > div": {
                  opacity: ".6"
                },
                "&:hover": {
                  textDecoration: "underline",
                  color: "white",
                  "& > div": {
                    opacity: "1"
                  }
                }
              }
            }}
          >
            <Flex justifyContent={["center"]} flexDirection={["column", "row"]} flexWrap="wrap">
              <Box mr={[0, 3, 3, 6]} ml={[0, 3, 3, 0]} mb={[4, 4, 4, 4, 0]}>
                <a href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}>
                  <Flex alignItems="center">
                    <Flex alignItems="center" color="white" pr={2}>
                      <Icon icon="phone" size={20} />
                    </Flex>
                    <Text>{footerSettings.phoneFooter}</Text>
                  </Flex>
                </a>
              </Box>
              <Box mr={[0, 3, 3, 6]} ml={[0, 3, 3, 0]} mb={[4, 4, 4, 4, 0]}>
                <a href={mailTo + footerSettings.mailFooter}>
                  <Flex alignItems="center">
                    <Flex alignItems="center" color="white" pr={2}>
                      <Icon icon="mail" size={20} />
                    </Flex>
                    <Text>{footerSettings.mailFooter}</Text>
                  </Flex>
                </a>
              </Box>
              <Box mr={[0, 3, 3, 6]} ml={[0, 3, 3, 0]} mb={[4, 4, 4, 4, 0]}>
                <a href={footerSettings.waterWebsite} target="_blank" rel="noreferrer">
                  <Flex alignItems="center">
                    <Flex alignItems="center" color="white" pr={2}>
                      <Icon icon="link-external" size={20} />
                    </Flex>
                    <Text>
                      {footerSettings.waterWebsite
                        .split("//")
                        .pop()
                        .replace(/^\/|\/$/g, "")}
                    </Text>
                  </Flex>
                </a>
              </Box>
            </Flex>
          </Flex>
          <Flex
            justifyContent={["center"]}
            flexDirection={["column", "column", "column", "column", "row"]}
            alignItems={["center"]}
            flexWrap="wrap"
            py={5}
            sx={{
              a: {
                color: "white",
                textDecoration: "none",
                "& > div": {
                  opacity: ".6"
                },
                "&:hover": {
                  textDecoration: "underline",
                  color: "white",
                  "& > div": {
                    opacity: "1"
                  }
                }
              }
            }}
          >
            <Box mr={[0, 3, 3, 6]} ml={[0, 3, 3, 0]} mb={[4, 4, 4, 4, 0]}>
              <Flex>
                <Text mr={[0, 1, 1, 1]} color="hightlight" opacity={0.6}>
                  Realisation :{" "}
                </Text>
                <a href="https://la-fabrik.nc" target="blank">
                  <Text> La Fabrik </Text>
                </a>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    companyName: PropTypes.any,
    addressFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any,
    mailFooter: PropTypes.any,
    opentimeFooter: PropTypes.any,
    waterWebsite: PropTypes.any,
    socials: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    mainmenu: PropTypes.array,
    mainmenuen: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  productsList: PropTypes.shape({
    edges: PropTypes.any
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme }) => {
  const { menufooterQuery, productsQuery, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        menufooterQuery: allMarkdownRemark(
          filter: { frontmatter: { title: { in: ["menus", "footer"] } } }
        ) {
          nodes {
            frontmatter {
              title
              activeFooter
              mainmenu {
                offset
                path
                title
                external
              }
              mainmenuen {
                offset
                path
                title
                external
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              companyName
              addressFooter
              phoneFooter
              mailFooter
              waterWebsite
              socials {
                facebook
              }
            }
          }
        }
        productsQuery: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "ProductTemplate" } } }
          sort: { fields: [frontmatter___weight], order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                title
                slug
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    menufooterQuery.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    menufooterQuery.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      productsList={productsQuery}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
