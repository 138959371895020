import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ThemeProvider } from "styled-components";
import { Box } from "rebass/styled-components";
import { GlobalStyle, SiteWrapper } from "../../theme/Site.style";
import { buildTheme } from "../../theme";
import { useStaticQuery, graphql } from "gatsby";
import CookiesBanner from "./CookiesBanner";
import SEO from "./seo";
import PageTitle from "../UI/PageTitle";
import PatternLeft from "../../theme/img/pattern-left-gray.svg";
import PatternRight from "../../theme/img/pattern-right-gray.svg";

import LangProvider from "./langContext";

const Layout = ({
  title,
  description,
  thumbnail,
  lang,
  i18nPaths,
  children,
  raw,
  slug,
  headerImage,
  path,
  supTitle
}) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      {
        markdownRemark(frontmatter: { settingName: { eq: "theme" } }) {
          frontmatter {
            breakpoints {
              small
              medium
              large
              huge
            }
            colors {
              background
              danger
              dangerHover
              footer
              gray
              gray10
              gray30
              gray50
              grayDark
              grayLight
              highlight
              muted
              primary
              primaryHover
              secondary
              primaryLight
              secondaryHover
              success
              successHover
              text
              textHover
              warning
              warningHover
            }
            font {
              fontname
              fontsizes
            }
            fontheads {
              fontname
              fontsizes
            }
          }
        }
      }
    `
  );
  const themeSettings = markdownRemark.frontmatter;
  const theme = buildTheme(themeSettings);
  raw = raw || false;
  const [headerHeight, setHeaderHeight] = useState(-1);
  const [position, setPosition] = useState(-1);
  const handleScroll = () => {
    setHeaderHeight(document.getElementById("navbar").clientHeight - 20);
    setPosition(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <LangProvider lang={lang} i18nPaths={i18nPaths} path={path}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SiteWrapper>
          <SEO title={title} description={description} thumbnail={thumbnail} />
          <Navbar path={path} />
          <Box
            pt={position == 0 ? 0 : headerHeight}
            sx={{
              position: "relative",
              "&:before, &:after": {
                content: "''",
                display: ["none", "none", "none", "none", "block"],
                width: "90px",
                height: "100%",
                position: "absolute",
                top: 0,
                backgroundRepeat: "repeat-y",
                backgroundSize: "100% auto",
                zIndex: 1
              },
              "&:before": {
                left: 0,
                backgroundImage: "url(" + PatternLeft + ")"
              },
              "&:after": {
                right: 0,
                backgroundImage: "url(" + PatternRight + ")"
              },
              "& > *": {
                position: "relative",
                zIndex: 2
              }
            }}
          >
            {slug && !slug.includes("index") && (
              <PageTitle title={title} headerImage={headerImage} path={path} supTitle={supTitle} />
            )}
            {children}
          </Box>
          <Footer theme={theme} />
          <CookiesBanner />
        </SiteWrapper>
      </ThemeProvider>
    </LangProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  description: PropTypes.any,
  i18nPaths: PropTypes.any,
  lang: PropTypes.any,
  raw: PropTypes.bool,
  theme: PropTypes.any,
  thumbnail: PropTypes.any,
  title: PropTypes.any,
  headerImage: PropTypes.any,
  slug: PropTypes.any,
  path: PropTypes.any,
  supTitle: PropTypes.any
};

export default Layout;
