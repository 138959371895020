import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass/styled-components";
import PatternLeft from "../../theme/img/pattern-left-gray.svg";

const Drawer = ({
  children,
  closeButton,
  drawerHandler,
  toggleHandler,
  open,
  placement,
  width
}) => {
  // console.log(placement);
  const [opened, setOpened] = useState(false);

  const [delay, setDelay] = useState(false);
  useEffect(() => {
    setDelay(
      setTimeout(
        function() {
          setOpened(open);
        },
        open ? 0 : 300
      )
    );
    return () => {
      clearTimeout(delay);
    };
  }, [open]);
  const getTransform = () => {
    const positions = {
      right: open && opened ? "translate(0%,0%)" : "translate(100%,0%)",
      left: open && opened ? "translate(0%,0%)" : "translate(-100%,0%)",
      bottom: open && opened ? "translate(0%,0%)" : "translate(0%,100%)",
      top: open && opened ? "translate(0%,0%)" : "translate(0%,-100%)"
    };
    return positions[placement];
  };
  return (
    <>
      <Box
        sx={{
          display: open ? "block" : opened ? "block" : "none",
          opacity: open && opened ? 1 : 0,
          transition: "all 300ms ease",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9999,
          background: "rgba(0, 0, 0, 0.3)"
        }}
        onClick={toggleHandler}
      ></Box>
      <Box
        sx={{
          display: "block",
          transition: "all 300ms ease",
          position: "fixed",
          background: "#fff",
          bottom: 0,
          top: 0,
          left: placement !== "right" ? 0 : "unset",
          right: placement !== "left" ? 0 : "unset",
          zIndex: 9999,
          transform: getTransform(),
          width: placement === "top" || placement === "bottom" ? "100%" : width,
          "&:before": {
            content: "''",
            display: "block",
            width: "40px",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundImage: "url(" + PatternLeft + ")",
            backgroundRepeat: "repeat-y",
            backgroundSize: "100% auto",
            zIndex: 1
          },
          "& > *": {
            position: "relative",
            zIndex: 2
          }
        }}
      >
        <Box
          pr={[3, 3, 6]}
          pl={11}
          pb={3}
          sx={{
            overflow: "auto",
            height: "100%"
          }}
        >
          <div onClick={toggleHandler}>{closeButton}</div>
          {children}
        </Box>
      </Box>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <div style={{ display: "inline-block" }} onClick={toggleHandler}>
          {drawerHandler}
        </div>
      </Flex>
    </>
  );
};

Drawer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string, //* Used to render icon, button, text or any elements inside the closeButton prop.
  closeButton: PropTypes.element, //* Set drawer width. Default value is 300px.
  drawerHandler: PropTypes.element.isRequired,
  handler: PropTypes.bool,
  level: PropTypes.any,
  open: PropTypes.any,
  placement: PropTypes.oneOf(["left", "right", "top", "bottom"]), //* Set drawer position left || right || top || bottom.
  toggleHandler: PropTypes.func,
  width: PropTypes.any //* Set drawer position left || right || top || bottom.
};

Drawer.defaultProps = {
  handler: false,
  level: null,
  placement: "right",
  toggleHandler: () => null,
  width: ["100%", "400px"]
};

export default Drawer;
