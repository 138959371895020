import PropTypes from "prop-types";
import React from "react";
import { Flex, Box, Heading, Text } from "rebass/styled-components";
import Container from "./Container";
import { withTheme } from "styled-components";

const PageTitle = ({ title, headerImage, theme, path, supTitle, ...props }) => {
  const isProduct = path.includes("/produits") || path.includes("/products") ? true : false;
  return (
    <Flex
      sx={{
        position: "relative",
        "&:before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "102vw",
          height: "100%",
          background: headerImage
            ? `url(${headerImage.childImageSharp.fluid.src}) no-repeat center`
            : theme.colors.secondary,
          backgroundSize: "cover",
          zIndex: 0
        },
        "&:after": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          backgroundColor: isProduct ? theme.colors.secondary : theme.colors.primary,
          opacity: ".75"
        }
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          mt: "auto"
        }}
      >
        <Flex
          py={6}
          minHeight={["auto", "auto", "150px"]}
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          {supTitle && (
            <Text variant="supTitle" mb={0} color="white">
              {supTitle}
            </Text>
          )}
          <Heading as="h1" variant="h1" color="white">
            {title}
          </Heading>
        </Flex>
      </Container>
    </Flex>
  );
};

PageTitle.propTypes = {
  title: PropTypes.any,
  headerImage: PropTypes.any,
  path: PropTypes.any,
  supTitle: PropTypes.any,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any,
      secondary: PropTypes.any
    })
  })
};

export default withTheme(PageTitle);
