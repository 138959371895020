const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    transition: "all 300ms ease",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  formItemError: {
    "input,textarea,select": {
      borderColor: "danger"
    },
    ".checkit": {
      color: "danger"
    }
  },
  navbar: {
    width: "100%",
    transition: "all 300ms ease",
    backgroundColor: "white",
    color: "#FFF",
    zIndex: 10,
    color: "text",
    boxShadow: "default",
    a: {
      color: "text"
    },
    ".logo": {
      display: "block",
      lineHeight: 0
    }
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "960px", "1200px"],
    m: "0 auto",
    flexbox: true,
    px: [3, 3, 6]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: 0
  },
  grid: {
    flexWrap: "wrap",
    mx: [-2, -2, -3]
  },
  gridItem: {
    px: [2, 2, 3]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },
  card: {
    boxShadow: "card",
    borderBottom: "5px solid",
    borderColor: "primary",
    borderRadius: "big",
    overflow: "hidden"
  },
  horizontalCard: { variant: "variants.card" },
  verticalCard: { variant: "variants.card" },
  // blockTitle - blockTitleWhite - blockTitleCenter - blockTitleWhiteCenter
  blockTitle: {
    variant: "text.h2",
    "&:after": {
      content: "''",
      display: "block",
      width: "100px",
      height: "1px",
      mt: 4,
      bg: "grayLight"
    }
  },
  blockTitleWhite: {
    variant: "variants.blockTitle",
    color: "white",
    "&:after": {
      content: "''",
      display: "block",
      width: "100px",
      height: "1px",
      mt: 3,
      bg: "white"
    }
  },
  blockTitleCenter: {
    variant: "variants.blockTitle",
    textAlign: "center",
    "&:after": {
      content: "''",
      display: "block",
      width: "100px",
      height: "1px",
      mt: 3,
      bg: "secondary",
      mx: "auto"
    }
  },
  blockTitleWhiteCenter: {
    variant: "variants.blockTitleCenter",
    color: "white",
    "&:after": {
      content: "''",
      display: "block",
      width: "100px",
      height: "1px",
      mt: 3,
      bg: "white",
      mx: "auto"
    }
  },
  blockTitleInline: {
    variant: "text.h2",
    display: "inline-flex",
    width: "100%",
    "&:after": {
      content: "''",
      display: "block",
      height: "1px",
      mt: "0",
      bg: "grayLight",
      flex: "1",
      ml: 3,
      mt: "23px"
    }
  },
  mainMenuItem: {
    a: {
      button: {
        fontWeight: "bold"
      },
      "&.active": {
        button: {
          color: "primary"
        }
      }
    },
    "&.menu-item-desktop": {
      a: {
        position: "relative",
        paddingBottom: "10px",
        textDecoration: "none",
        "&:before, &:after": {
          content: "''",
          display: "block",
          height: "4px",
          position: "absolute",
          top: "100%",
          left: 0,
          transition: "all 300ms ease"
        },
        "&:before": {
          width: "100%",
          bg: "grayLight"
        },
        "&:after": {
          width: "0",
          bg: "primary"
        },
        "&:hover, &:focus": {
          button: {
            color: "text"
          }
        },
        "&.active": {
          button: {
            color: "primary"
          }
        },
        "&.active, &:hover, &:focus": {
          "&:after": {
            width: "100%"
          }
        }
      },
      "& > a": {
        display: "flex",
        ".menu-item-icon": {
          position: "relative",
          lineHeight: 0,
          ml: 1,
          transition: "all 300ms ease",
          transform: "rotate(0)"
        },
        "&:hover, &:focus": {
          ".menu-item-icon": {
            transform: "rotate(180deg)"
          }
        }
      }
    }
  }
};

export default variants;
