import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

export const LangContext = React.createContext({});

const LangProvider = ({ lang, children, i18nPaths, path }) => {
  lang = lang || "en";
  i18nPaths = i18nPaths || {};
  path = path || false;
  const [currentLanguage, setCurrentLanguage] = useState(lang);
  useEffect(() => {
    if (lang !== currentLanguage) {
      setCurrentLanguage(lang);
    }
  }, [lang, currentLanguage]);
  useEffect(() => {
    if (i18nPaths.hasOwnProperty(currentLanguage)) {
      if (path !== i18nPaths[currentLanguage]) {
        navigate(i18nPaths[currentLanguage]);
      }
    }
  }, [currentLanguage]);
  return (
    <LangContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {children}
    </LangContext.Provider>
  );
};

LangProvider.propTypes = {
  children: PropTypes.any,
  i18nPaths: PropTypes.object,
  lang: PropTypes.string,
  path: PropTypes.bool
};

export default LangProvider;
