import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Box, Button, Text } from "rebass/styled-components";
import { LangContext } from "../Layout/langContext";
import { useStaticQuery, graphql } from "gatsby";
const LangSwitcher = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
        frontmatter {
          locales
        }
      }
    }
  `);
  const siteinfos = data.markdownRemark.frontmatter;
  const { currentLanguage, setCurrentLanguage } = useContext(LangContext);
  if (siteinfos.locales.length > 1) {
    const sx = {
      position: "relative",
      overflow: "hidden",
      display: "flex",
      a: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ml: [0, 0, 0, 2],
        mr: [2, 2, 2, 0],
        p: 0,
        width: "auto",
        minWidth: "34px",
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "text",
        bg: "transparent",
        px: 1,
        py: 1,
        opacity: ".4",
        textTransform: "uppercase",
        img: {
          display: "block",
          width: "30px",
          height: "auto",
          mr: 2
        },
        "&:hover": {
          opacity: "1",
          borderColor: "primary",
          color: "primary"
        },
        "&.active": {
          opacity: "1",
          borderColor: "text",
          color: "text"
        }
      }
    };
    return (
      <Box sx={sx}>
        {siteinfos.locales
          .filter(locale => locale === currentLanguage)
          .map((locale, i) => (
            <Button
              key={i}
              as="a"
              className="active"
              onClick={() => setCurrentLanguage(locale)}
              variant="ninja"
            >
              {locale}
            </Button>
          ))}
        {siteinfos.locales
          .filter(locale => locale !== currentLanguage)
          .map((locale, i) => (
            <Button key={i} as="a" onClick={() => setCurrentLanguage(locale)} variant="ninja">
              {locale}
            </Button>
          ))}
      </Box>
    );
  }
  return <></>;
};

LangSwitcher.propTypes = {
  lang: PropTypes.any
};

export default LangSwitcher;
