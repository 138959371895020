import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Link from "./Link";
import Icon from "./Icon";

const MenuItem = ({ href, label, external, mode, lang, path, ...props }) => {
  mode = mode || false;
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "ProductTemplate" } } }
          sort: { fields: [frontmatter___weight], order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                slug
              }
            }
          }
        }
      }
    `
  );
  const isProduct = href.includes("/produits") || href.includes("/products") ? true : false;
  if (mode == "mobile") {
    return (
      <Box variant="mainMenuItem">
        <Link href={href} external={external}>
          <Button variant="ninja" py={2} mb={4} width="100%" textAlign="left">
            {label}
          </Button>
        </Link>
      </Box>
    );
  }
  return (
    <Flex
      alignItems="center"
      variant="mainMenuItem"
      className="menu-item-desktop"
      sx={{
        position: "relative",
        height: "100%",
        "& > div.sub-menu": {
          visibility: "hidden",
          opacity: 0,
          boxShadow: "hover",
          a: {
            whiteSpace: "nowrap",
            py: 2,
            px: 6,
            "&:before,&:after": {
              display: "none"
            },
            "&:hover": {
              bg: "grayLight"
            }
          }
        },
        "&:hover": {
          "& > div.sub-menu": {
            visibility: "visible",
            opacity: "1"
          }
        }
      }}
    >
      <Link activeClassName="active" href={href} external={external}>
        <Button variant="ninja">{label}</Button>
        {isProduct && (
          <Box color="primary" className="menu-item-icon">
            <Icon icon="chevron-down" size={18} />
          </Box>
        )}
      </Link>
      {isProduct && (
        <Flex
          flexDirection="column"
          className="sub-menu"
          sx={{
            position: "absolute",
            transition: "300ms all ease",
            bg: "white",
            top: "100%",
            left: "0"
          }}
        >
          {allMarkdownRemark.edges.map(
            (item, i) =>
              item.node.fields.slug.endsWith("." + lang + "/") && (
                <Link
                  key={i}
                  currentPath={path}
                  href={
                    lang == "fr" ? "/fr" + item.node.frontmatter.slug : item.node.frontmatter.slug
                  }
                >
                  <Button variant="ninja" sx={{ fontWeight: "normal !important" }}>
                    {item.node.frontmatter.title}
                  </Button>
                </Link>
              )
          )}
        </Flex>
      )}
    </Flex>
  );
};

MenuItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  external: PropTypes.bool,
  mode: PropTypes.string,
  slug: PropTypes.string,
  lang: PropTypes.string,
  path: PropTypes.string
};

export default MenuItem;
