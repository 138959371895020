import colors from "./colors";
import { buildButtons } from "./buttons";
import variants from "./variants";
import forms from "./forms";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 17, 18, 20, 22, 24, 26, 28, 30, 38, 45, 55],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 70, 100, 120, 150, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 4,
    big: 8,
    circle: 99999
  },
  shadows: {
    default: "0 0 10px rgba(0, 0, 0, .15)",
    hover: "0 10px 10px rgba(0, 0, 0, .15)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading"
    },
    h1: {
      fontSize: 12,
      fontFamily: "body",
      textTransform: "uppercase"
    },
    h2: {
      fontSize: 11,
      fontFamily: "body",
      mb: 4,
      textTransform: "uppercase"
    },
    h3: {
      fontSize: 10,
      fontFamily: "body",
      mb: 3,
      textTransform: "uppercase"
    },
    h4: {
      fontSize: 8,
      mb: 2,
      textTransform: "none"
    },
    h5: {
      fontSize: 5,
      mb: 2,
      textTransform: "uppercase"
    },
    display: {
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    },
    supTitle: {
      color: "secondary",
      textTransform: "uppercase",
      fontSize: "3",
      fontFamily: "heading",
      mb: 1
    }
  },
  variants: variants,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  gutter: 30
};
export default theme;

const buildTheme = settings => {
  theme.fonts = {
    body: settings.font.fontname + ", sans-serif",
    heading: settings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace"
  };

  theme.colors = {
    ...theme.colors,
    ...settings.colors
  };
  theme.buttons = buildButtons(theme.colors);
  theme.breakpoints = [
    settings.breakpoints.small,
    settings.breakpoints.medium,
    settings.breakpoints.large,
    settings.breakpoints.huge
  ];
  return theme;
};

export { buildTheme };
