import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { LangContext } from "./langContext";
import Drawer from "./Drawer";
import Container from "../UI/Container";
import Link from "../UI/Link";
import CustomImage from "../UI/CustomImage";
import LangSwitcher from "../UI/LangSwitcher";
import Icon from "../UI/Icon";
import MenuItem from "../UI/MenuItem";

const NavbarRaw = ({ menusSettings, footerSettings, logoAlt, path }) => {
  logoAlt = logoAlt || "Logo";
  var telLink = "tel:";
  var mailTo = "mailto:";
  const { currentLanguage } = useContext(LangContext);
  const mainMenu = currentLanguage == "fr" ? menusSettings.mainmenu : menusSettings.mainmenuen;
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
    !open == true
      ? document.body.classList.add("menu-open")
      : document.body.classList.remove("menu-open");
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setStiked(position > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Box
      top={0}
      width={1}
      sx={{
        zIndex: 5,
        position: stiked ? "fixed" : "relative"
      }}
    >
      <Box id="navbar" variant="navbar" className={stiked ? "sticky" : "sticky-off"}>
        <Container>
          <Flex
            justifyContent="space-between"
            alignItems={["center", "center", "stretch"]}
            minHeight={["70px"]}
          >
            <Box
              width={stiked ? ["75px", "90px", "150px"] : ["100px", "120px", "200px"]}
              sx={{
                transition: "all 250ms ease",
                a: { display: "block" }
              }}
              py={2}
            >
              <Link href={currentLanguage == "fr" ? "/fr" : "/"}>
                <CustomImage
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box
              px={[0, 0, 0, 6, 10]}
              mx="auto"
              display={["none", "none", "none", "flex"]}
              alignItems="center"
              justifyContent="space-around"
              sx={{ flex: "1" }}
            >
              {mainMenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <MenuItem
                    href={item.path}
                    label={item.title}
                    external={item.external}
                    lang={currentLanguage}
                    path={path}
                    key={"menu-" + i}
                  />
                ))}
            </Box>
            {/* <Box display={["none", "none", "none", "flex"]} alignItems="center"> */}
            <Box display="none" alignItems="center">
              <LangSwitcher />
            </Box>
            <Drawer
              placement="right"
              width={["100%", "375px"]}
              closeButton={
                <Flex justifyContent="space-between" alignItems="center" pb={10}>
                  <Box
                    width={["100px", "120px"]}
                    sx={{
                      transition: "all 250ms ease",
                      a: { display: "block" }
                    }}
                    py={2}
                  >
                    <Link href="/">
                      <CustomImage
                        img={stiked ? menusSettings.logo : menusSettings.logo2}
                        alt={logoAlt}
                      />
                    </Link>
                  </Box>
                  <Button
                    label="close-menu"
                    aria-label="close-menu"
                    variant="ninja"
                    display={["flex", "flex", "flex", "none"]}
                    alignItems="center"
                    sx={{
                      color: "primary",
                      div: {
                        color: "text"
                      },
                      "&:hover": {
                        div: {
                          color: "primary"
                        }
                      }
                    }}
                  >
                    <Text fontWeight="bold" mr={2}>
                      {currentLanguage == "en" ? "Close" : "Fermer"}
                    </Text>
                    <Icon icon="cross" size={18} />
                  </Button>
                </Flex>
              }
              drawerHandler={
                <Button
                  label="open-menu"
                  aria-label="open-menu"
                  variant="ninja"
                  pl={2}
                  display={["flex", "flex", "flex", "none"]}
                  alignItems="center"
                  sx={{
                    color: "primary",
                    div: {
                      color: "text"
                    },
                    "&:hover": {
                      div: {
                        color: "primary"
                      }
                    }
                  }}
                >
                  <Text fontWeight="bold" mr={2}>
                    Menu
                  </Text>
                  <Icon icon="menu" size={24} />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box>
                {mainMenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <MenuItem
                      href={item.path}
                      label={item.title}
                      external={item.external}
                      lang={currentLanguage}
                      path={path}
                      mode="mobile"
                      key={i}
                    />
                  ))}
              </Box>
              {footerSettings.socials && (
                <Box pt={2}>
                  <Text fontWeight="bold" mb={2}>
                    {currentLanguage == "en" ? "Follow us" : "Suivez-nous"}
                  </Text>
                  <Flex>
                    {footerSettings.socials.facebook && (
                      <Box
                        mr={4}
                        sx={{
                          a: {
                            textDecoration: "none"
                          }
                        }}
                      >
                        <a href={footerSettings.socials.facebook}>
                          <Icon icon="facebook" size={40} />
                        </a>
                      </Box>
                    )}
                  </Flex>
                </Box>
              )}
              <Box
                display="none"
                mt={6}
                pt={6}
                sx={{
                  borderTopColor: "grayLight",
                  borderTopStyle: "solid",
                  borderTopWidth: "1px"
                }}
              >
                <LangSwitcher />
              </Box>
              <Box
                mt={6}
                pt={6}
                sx={{
                  borderTopColor: "grayLight",
                  borderTopStyle: "solid",
                  borderTopWidth: "1px"
                }}
              >
                <Box>
                  <Box mb={4}>
                    <a
                      href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}
                    >
                      <Flex alignItems="center">
                        <Flex alignItems="center" color="primary" pr={2}>
                          <Icon icon="phone" size={20} />
                        </Flex>
                        <Text>{footerSettings.phoneFooter}</Text>
                      </Flex>
                    </a>
                  </Box>
                  <Box mb={4}>
                    <a href={mailTo + footerSettings.mailFooter}>
                      <Flex alignItems="center">
                        <Flex alignItems="center" color="primary" pr={2}>
                          <Icon icon="mail" size={20} />
                        </Flex>
                        <Text>{footerSettings.mailFooter}</Text>
                      </Flex>
                    </a>
                  </Box>
                  <Box mb={4}>
                    <a href={footerSettings.waterWebsite} target="_blank" rel="noreferrer">
                      <Flex alignItems="center">
                        <Flex alignItems="center" color="primary" pr={2}>
                          <Icon icon="link-external" size={20} />
                        </Flex>
                        <Text>
                          {footerSettings.waterWebsite
                            .split("//")
                            .pop()
                            .replace(/^\/|\/$/g, "")}
                        </Text>
                      </Flex>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  path: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    mainmenuen: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  footerSettings: PropTypes.any,
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme, path }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              logo2 {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              logo {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              activeTopMenu
              menutopbartype
              mainmenu {
                external
                inNavBar
                inSideBar
                offset
                path
                title
              }
              mainmenuen {
                external
                inNavBar
                inSideBar
                offset
                path
                title
              }
              footermenu {
                offset
                path
                title
                external
              }
              phoneFooter
              mailFooter
              waterWebsite
              socials {
                facebook
              }
            }
          }
        }
      }
    `
  );
  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <NavbarRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
      path={path}
    />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any,
  path: PropTypes.any
};

export { NavbarRaw };
export default Navbar;
