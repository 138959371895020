import styled, { createGlobalStyle } from "styled-components";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
const GlobalStyle = createGlobalStyle`

@keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0,1%,0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0,1%,0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0,1%,0) rotateZ(-360deg);
}
}
@keyframes wave2 {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(-360deg) translate3d(0,1%,0) rotateZ(360deg);
    -moz-transform: rotateZ(-360deg) translate3d(0,1%,0) rotateZ(360deg);
    transform: rotateZ(-360deg) translate3d(0,1%,0) rotateZ(360deg);
}
}
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    &:focus {
      outline: none;
      outline-color: transparent;
      outline-style: auto;
      outline-width: 0px;
    }
  }
  pre {
    overflow-x: scroll;
  }
img[src="/img/prima-imgs/rules/rules-pc.jpg"]{
  display:none;
}
@media (min-width: 992px) {
img[src="/img/prima-imgs/rules/rules-pc.jpg"]{
  display:block;
}
 }
img[src="/img/prima-imgs/rules/rules-tablet.jpg"]{
  display:none;
}
@media (max-width: 991px) and (min-width: 576px){
img[src="/img/prima-imgs/rules/rules-tablet.jpg"]{
  display:block;
}
 }
@media (min-width: 575px){
img[src="/img/prima-imgs/rules/rules-sm.jpg"]{
  display:none;
}
 }
  body{
    margin: 0;
    padding: 0;
	  overflow-x: hidden;
    font-size: ${themeGet("fontSizes.2")}px;
    font-family: ${themeGet("fonts.body")};
    line-height: ${themeGet("lineHeights.body")};
    color: ${themeGet("colors.text")};
    &.menu-open {
      overflow: hidden;
    }
    img {
      max-width:100%;
    }
    div {
      & > p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    a {
      color: ${themeGet("colors.text")};
      &:hover {
        color: ${themeGet("colors.primary")};
      }
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${themeGet("fonts.heading")};
    font-weight: ${themeGet("fontWeights.heading")};
  }
  h1 {
    font-size: ${themeGet("fontSizes.12")}px;
    font-family: ${themeGet("fonts.body")};
    text-transform: uppercase;
    color: white;
  }
  h2 {
    font-size: ${themeGet("fontSizes.11")}px;
    font-family: ${themeGet("fonts.body")};
    text-transform: uppercase;
    margin-bottom: ${themeGet("space.4")}px;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: ${themeGet("fontSizes.10")}px;
    font-family: ${themeGet("fonts.body")};
    text-transform: uppercase;
    margin-bottom: ${themeGet("space.3")}px;
  }
  h4 {
    font-size: ${themeGet("fontSizes.8")}px;
    margin-bottom: ${themeGet("space.2")}px;
  }
  h5 {
    font-size: ${themeGet("fontSizes.5")}px;
    margin-bottom: ${themeGet("space.2")}px;
    text-transform: uppercase;
  }
  .react-parallax-bgimage{
    max-width:unset;
  }
  .mapboxgl-ctrl-attrib{
    display:none !important;
  }
`;

const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
