const buildButtons = colors => {
  const buttons = {};

  const bigProps = {
    fontSize: 2,
    px: 6,
    py: 3
  };
  const smallProps = {
    fontSize: "0.7em",
    px: 2,
    py: 1
  };
  const buttonColors = ["primary", "secondary", "success", "danger", "warning", "text"];

  for (var i = 0; i < buttonColors.length; i++) {
    let buttonColor = buttonColors[i];
    let gradiantColor = colors[buttonColor + "Gradiant"] || colors[buttonColor + "Hover"];
    buttons[buttonColor] = {
      variant: "buttons.base",
      backgroundColor: buttonColor,
      borderColor: buttonColor,
      "&:hover": {
        bg: buttonColor + "Hover",
        borderColor: buttonColor + "Hover"
      }
    };
    buttons[buttonColor + "Small"] = {
      ...buttons[buttonColor],
      ...smallProps
    };

    buttons[buttonColor + "Big"] = {
      ...buttons[buttonColor],
      ...bigProps
    };
    buttons[buttonColor + "Outline"] = {
      variant: "buttons.base",
      borderColor: buttonColor,
      color: buttonColor,
      bg: "transparent",
      "&:hover": {
        bg: "transparent",
        borderColor: buttonColor + "Hover",
        color: buttonColor + "Hover"
      }
    };
    buttons[buttonColor + "OutlineSmall"] = {
      ...buttons[buttonColor + "Outline"],
      ...smallProps
    };

    buttons[buttonColor + "OutlineBig"] = {
      ...buttons[buttonColor + "Outline"],
      ...bigProps
    };
    buttons[buttonColor + "OutlinePlain"] = {
      variant: "buttons.base",
      borderColor: buttonColor,
      color: buttonColor,
      bg: "transparent",
      "&:hover": {
        bg: buttonColor + "Hover",
        borderColor: buttonColor + "Hover",
        color: "white"
      }
    };
    buttons[buttonColor + "OutlinePlainSmall"] = {
      ...buttons[buttonColor + "OutlinePlain"],
      ...smallProps
    };

    buttons[buttonColor + "OutlinePlainBig"] = {
      ...buttons[buttonColor + "OutlinePlain"],
      ...bigProps
    };
    buttons[buttonColor + "Gradiant"] = {
      variant: "buttons.base",
      backgroundSize: "200% 200%",
      backgroundPosition: "left 50%",
      backgroundRepeat: "repeat-x",
      "&:hover": { backgroundPosition: "right 50%" },
      backgroundColor: buttonColor,
      backgroundImage:
        "linear-gradient(90deg, " +
        gradiantColor +
        " , " +
        colors[buttonColor] +
        " 50%," +
        gradiantColor +
        ");"
    };
    buttons[buttonColor + "GradiantSmall"] = {
      ...buttons[buttonColor + "Gradiant"],
      ...smallProps
    };
    buttons[buttonColor + "GradiantBig"] = {
      ...buttons[buttonColor + "Gradiant"],
      ...bigProps
    };
  }

  buttons.base = {
    fontFamily: "body",
    fontSize: 3,
    fontWeight: "bold",
    color: "white",
    bg: "text",
    borderRadius: "0",
    cursor: "pointer",
    transition: "all 300ms ease",
    border: "1px solid",
    borderColor: "text",
    lineHeight: "1",
    py: 3,
    px: 4,
    display: "inline-flex",
    alignItems: "center",
    svg: {
      ml: 2,
      width: "18px",
      height: "18px",
      transition: "transform 300ms ease"
    },
    "&:hover": {
      color: "white",
      bg: "primary",
      borderColor: "primary"
    }
  };
  buttons.invert = {
    variant: "buttons.base",
    color: "text",
    bg: "white",
    borderColor: "white",
    "&:hover": {
      color: "white",
      bg: "text",
      borderColor: "text"
    }
  };
  buttons.outline = {
    variant: "buttons.invert",
    borderColor: "grayLight",
    "&:hover": {
      color: "primary",
      bg: "white",
      borderColor: "primary"
    }
  };
  buttons.outlineWhite = {
    variant: "buttons.invert",
    color: "white",
    borderColor: "white",
    bg: "transparent",
    "&:hover": {
      color: "text",
      bg: "white",
      borderColor: "white"
    }
  };
  buttons.transparent = {
    variant: "buttons.outline",
    bg: "transparent",
    "&:hover": {
      bg: "transparent"
    }
  };
  buttons.nav = {
    variant: "buttons.base",
    color: "gray",
    bg: "white",
    borderColor: "white",
    px: 3,
    py: 3,
    display: "flex",
    alignItems: "center",
    svg: {
      ml: 0
    },
    "&:hover": {
      color: "primary",
      bg: "white",
      borderColor: "white",
      svg: {
        fill: "primary",
        path: {
          fill: "primary"
        }
      }
    }
  };
  buttons.ninja = {
    fontFamily: "body",
    color: "inherit",
    bg: "transparent",
    m: 0,
    p: 0,
    border: "none",
    borderRadius: "0",
    cursor: "pointer",
    lineHeight: "1",
    "&:hover": {
      bg: "transparent",
      opacity: 1
    }
  };
  return buttons;
};
export { buildButtons };
